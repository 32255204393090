import React from 'react';
import { Link } from 'gatsby';
import Layout from '../../layouts/index';
import HandingEndoscopeImg from '../../images/handing-endoscope.jpg'
import HirahataClinicSignboardImg from '../../images/hirahata-clinic-signboard.jpg'
import FrontOfShibuyaStationImg from '../../images/front-of-shibuya-station.jpg'
import UltrasonicReflectoscopeImg from '../../images/ultrasonic-reflectoscope.jpg'
import ValueVsCostImg from '../../images/value-vs-cost.jpg'

const HealthCheckingContent = () => (
  <div className="container sub-content">
    <h1>健康診断について 当院の特徴</h1>
    <div className="row box">
      <div className="col-sm-4">
        <img
          className="img-with-text img-thumbnail center-block"
          src={HandingEndoscopeImg}
        />
      </div>
      <div className="col-sm-8">
        <h3>高い専門性</h3>
        <p>
          院長は日本消化器内視鏡学会専門医、日本内科学会認定医、日本消化管学会認定医。
          <br />
          循環器内科、呼吸器内科の研修を経ており、健診・ドックに関連するあらゆる分野について的確にアドバイスが可能です。
          <br />
          また、ドックの核心部分である腹部超音波、胃カメラ、大腸カメラについては高い専門性を有しています。
          <br />
          （内視鏡検査は全例、日本内視鏡学会認定専門医が施行します。）
          <br />
          <Link to="/about-us/equipments#endoscope">内視鏡検査について</Link>
        </p>
      </div>
    </div>
    <div className="row box">
      <div className="col-sm-4">
        <img
          className="img-with-text img-thumbnail center-block"
          src={HirahataClinicSignboardImg}
        />
      </div>
      <div className="col-sm-8">
        <h3>アフターフォローの充実</h3>
        <p>
          当院では内科だけでなく、循環器内科・呼吸器内科の診察も行っており、幅広い分野の問題に対処できます。
          <br />
          また、近隣の大病院とも緊密に連携を取っており、すぐにご紹介が可能です。
        </p>
      </div>
    </div>
    <div className="row box">
      <div className="col-sm-4">
        <img
          className="img-with-text img-thumbnail center-block"
          src={FrontOfShibuyaStationImg}
        />
      </div>
      <div className="col-sm-8">
        <h3>高い利便性</h3>
        <p>
          当院は渋谷駅から徒歩２分という至便な場所にあります。さらに、当院は朝９時から夜７時までの診療受付となります。
          <br />
          忙しい方でも利用しやすい環境を整えており、夜、会社帰りに健診を受けていただくことも可能です。
          <br />
          詳しくは<Link to="/about-us#access">アクセス欄</Link>を御覧ください。
        </p>
      </div>
    </div>
    <div className="row box">
      <div className="col-sm-4">
        <img
          className="img-with-text img-thumbnail center-block"
          src={UltrasonicReflectoscopeImg}
        />
      </div>
      <div className="col-sm-8">
        <h3>超音波専門女性技師によるエコー検査</h3>
        <p>
          当院では、超音波専門のベテラン女性技師によるエコー検査を提供しています。
          <br />
          腹部エコー、乳腺エコーの他、頚動脈エコー、心エコー、甲状腺エコー等も施行可能です。
          <br />
          また、健診センターなどでは数をこなさなければならないため、どうしても流れ作業的な短時間の検査しかできないのが実情ですが、当院では枠を減らすことでたっぷりと検査時間を用意。
          <br />
          大雑把な検査ではなく、見逃しの少ない、しっかりとした検査を受けることができます。
          <br />
          （例：一般的な健診センターでの腹部エコー検査は６～７分程度。当院では１０～２０分かけてしっかり観察します。）
          <br />
          <Link to="/about-us/equipments#echo">エコー検査について</Link>
        </p>
      </div>
    </div>
    <div className="row box">
      <div className="col-sm-4">
        <img
          className="img-with-text img-thumbnail center-block"
          src={ValueVsCostImg}
        />
      </div>
      <div className="col-sm-8">
        <h3>リーズナブルな料金</h3>
        <p>
          当院では、より多くの方々が気軽に利用できるよう、リーズナブルな料金で健診･ドックを提供させていただいております。
          <br />
          （３名様以上の人間ドックをお申し込みいただくと、団体割引が
          適応されます。
          <br />
          ※ネットの予約サービスをご利用された場合は
          適応されません。また、一般検診・生活習慣病検診などでは団体割引はありません。）
          <br />
          詳しくは<Link to="/fee">料金ページ</Link>を御覧ください。
        </p>
      </div>
    </div>
  </div>
);

const HealthChecking = () => (
  <Layout>
    <HealthCheckingContent />
  </Layout>
);

export default HealthChecking;
